<template>
  <div id="register">
<!--    <van-row class="m-header">-->
<!--      <van-col span="24">-->
<!--&lt;!&ndash;        <van-icon class="m-header-icon" name="arrow-left"/>&ndash;&gt;-->
<!--        <span>注册</span>-->
<!--      </van-col>-->
<!--    </van-row>-->
    <p class="title">注册</p>
    <van-form @submit="onSubmit" autocomplete="off">
      <van-field
          v-model="phone"
          :rules="[{ required: true, message: '请输入手机号' }]"
          maxlength="11"
          autoComplete="off"
          placeholder="请输入手机号"
          type="tel"
      />
      <van-field
          v-model="code"
          autocomplete="off"
          type="number"
          :rules="[{ required: true, message: '请输入验证码' }]"
          placeholder="请输入验证码"
      >
        <template #button>
          <span :class="{'activeBtn': activeCode}" class="fieldBtn"
                @click="toGetCode">{{ count ? '重新获取(' + count + 's)' : '获取验证码' }}</span>
        </template>
      </van-field>
      <van-field
          v-model="password"
          autocomplete="new-password"
          :rules="[{ required: true, message: '请输入密码' }]"
          placeholder="请输入密码"
          type="password"
          @change="changePassword"
      />
      <van-field
          v-model="checkPassword"
          autocomplete="new-password"
          :rules="[{ required: true, message: '请再次输入密码' }]"
          placeholder="请再次输入密码"
          type="password"
          @change="changePassword"
      />
      <div class="bottomBtn">
        <van-button block native-type="submit" round type="info">注册</van-button>
        <div class="bottom">
          <van-checkbox v-model="checked" checked-color="#41E3AF">
            <p>已阅读并同意<span @click="toAgreement">《使用协议》</span>&<span @click="toPrivacy">《隐私协议》</span></p>
          </van-checkbox>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from 'vue';
import {useRouter} from 'vue-router'
import {Toast} from 'vant';
import {verifyPhone,devType} from '../../utils/toolsValidate';
// import {userRegister, sendSmsVerifyCode} from '../../api'

export default {
  name: "register",
  setup() {
    const router = useRouter();
    const state = reactive({
      phone: '',
      code: '',
      checkPassword: '',
      password: '',
      activeCode: false,
      checked: false,
      count: '',
      timer: null
    })
    const toAgreement = () => {
      router.push("/UserAgreement")
    }
    const toPrivacy = () => {
      router.push("/UserPrivacy")
    }
    const toGetCode = () => {
      if (!state.activeCode) return
      if (!verifyPhone(state.phone)) {
        return Toast.fail('请输入正确的手机号码');
      } else {
        const params = {
          phone: state.phone
        }
        console.log(params)
        // sendSmsVerifyCode(param).then(() => {
        //   const TIME_COUNT = 60;
        //   if (!state.timer) {
        //     state.count = TIME_COUNT;
        //     state.activeCode = false
        //     state.timer = setInterval(() => {
        //       if (state.count > 0 && state.count <= TIME_COUNT) {
        //         state.count--;
        //         state.activeCode = false
        //       } else {
        //         state.activeCode = true
        //         clearInterval(state.timer);
        //         state.timer = null;
        //       }
        //     }, 1000)
        //   }
        // })
      }
    }
    const changePassword = () => {
      if (state.password && state.checkPassword && state.checkPassword !== state.password) {
        Toast.fail('两次密码不一致');
        return false
      }
    }
    const valid = () => {
      if(!state.phone) {
        Toast.fail('请输入手机号！')
        return false
      }
      if(!state.code) {
        Toast.fail('请输入验证码！')
        return false
      }
      if(!state.password || !state.checkPassword) {
        Toast.fail('请输入密码！')
        return false
      }
      if(!state.checked) {
        Toast.fail('请勾选同意协议！')
        return false
      }
      return true
    }
    const reset = () => {
      state.phone= ''
      state.code= ''
      state.checkPassword= ''
      state.password= ''
      state.activeCode= false
      state.checked= false
      state.count= ''
      state.timer= null
    }
    const onSubmit = () => {
      if(!valid()) return
      const param = {
        accountType: 4,
        devType: devType(),
        password: state.password,
        phone: state.phone,
        smsCode: state.code,
        countryNumber: "86"
      }
      console.log(param)
      // userRegister(param).then(res => {
      //   if(res.data.result) {
      //     Toast.success('注册成功,请登录！')
      //     reset()
      //     router.push('/login')
      //   }
      // }).catch(() =>{})
    }
    watch(() => state.phone, (newVal) => {
      if (newVal && newVal.length == 11) {
        state.activeCode = true
      } else {
        state.activeCode = false
      }
    })
    onMounted(() => {
      reset()
      window.document.title = '注册';
    })
    return {
      onSubmit,
      toGetCode,
      toPrivacy,
      toAgreement,
      changePassword,
      ...toRefs(state),
    }
  }
}
</script>

<style lang="less" scoped>
#register {
  padding: 1.5rem .5rem 0 .5rem;
  background: #FFFFFF;
  height: 100vh;

  .title {
    font-size: 0.5rem;
    font-weight: 800;
    color: #333333;
    line-height: 0.7rem;
    margin-bottom: .83rem;
  }

  .van-field {
    //height: 0.8rem;
    margin-bottom: .4rem;
    background: #F4F5F7;
    border-radius: 0.4rem;
  }

  .fieldBtn {
    padding-left: .2rem;
    border-left: 1px solid #E4E4E4;
    width: 1.4rem;
    height: 0.4rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: #898989;
    line-height: 0.4rem;
  }

  .activeBtn {
    color: #41E3AF;
  }

  .van-toast__text {
    font-size: .24rem;
  }

  .bottomBtn {
    display: flex;
    flex-direction: column;
    align-items: center;

    .van-button {
      height: 0.78rem;
      background: #BCFFEA;
      border-radius: 0.39rem;
      font-size: 0.32rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 0.32rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
    }

    .bottom {
      margin-top: .26rem;
      display: flex;
      align-items: center;
      font-size: 0.3rem !important;
      font-weight: 400;
      color: #999999;
      line-height: 0.3rem !important;
      white-space: nowrap;

      p {
        font-size: .22rem !important;
        line-height: 0.22rem !important;
      }

      span {
        color: #41E3AF;
      }
    }
  }
}
</style>
